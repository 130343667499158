<template>
  <v-container class="not-extended">
    <v-data-table :search="search" v-bind="bind.table">
      <template v-slot:item.interes_asegurable="{ item }">
        <v-icon :color="item.interes_asegurable ? 'success' : 'grey'">{{
          item.interes_asegurable ? "mdi-checkbox-marked" : "mdi-checkbox-blank"
        }}</v-icon>
      </template>

      <template v-slot:top>
        <div flat class="d-flex align-center py-3 px-4">
          <v-text-field
            v-model="search"
            v-bind="bind.search"
            :label="labels.search"
          ></v-text-field>
          <v-divider v-bind="bind.divider"></v-divider>
          <v-spacer></v-spacer>
          <v-btn v-bind="bind.refresh" v-on="on.refresh">
            <v-icon>{{ icons.refresh }}</v-icon>
          </v-btn>
          <v-btn v-bind="bind.new" v-on="on.new">
            {{ labels.new }} <v-icon right>mdi-home-plus</v-icon>
          </v-btn>
        </div>
      </template>

      <template v-slot:item.dia_pago="{ item }">
        <v-chip-group active-class="primary--text" column>
          <v-chip v-for="(dia, i) in item.dia_pago.dias" :key="i" small>
            {{ dia }}
          </v-chip>
        </v-chip-group>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip left color="primary">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              x-small
              text
              fab
              @click="(selected = item), (dialog = true)"
              v-on="on"
              v-bind="attrs"
            >
              <v-icon small> {{ icons.edit }} </v-icon>
            </v-btn>
          </template>
          <span>Editar la información de {{ item.descripcion }}</span>
        </v-tooltip>
        <v-tooltip left color="error">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="error"
              x-small
              text
              fab
              @click="deleteItem(item)"
              v-on="on"
              v-bind="attrs"
              :disabled="item.eliminado"
              :loading="item.loading"
            >
              <v-icon small> {{ icons.delete }} </v-icon>
            </v-btn>
          </template>
          <span>Eliminar {{ item.descripcion }} del listado</span>
        </v-tooltip>
      </template>
    </v-data-table>

    <Modal v-model="dialog" v-bind="bind.form" v-on="on.form" />
    <confirm-modal :ref="refs.confirm" />
  </v-container>
</template>
<script>
import Modal from "@/components/Modal/Modal-Compania.vue";
import { mapActions, mapGetters } from "vuex";

import PageMixin from "@/Mixins/SetPageMixin.js";

export default {
  name: "CompanyPage",
  mixins: [PageMixin],
  components: {
    Modal,
  },
  data: () => ({
    state_modal_delete: false,
    titulomodal: "Compañia",
    headers: [
      { text: "Código", value: "codigo", align: "start", sortable: true },
      {
        text: "Descripción",
        value: "descripcion",
        align: "start",
        sortable: true,
      },
      {
        text: "Interes",
        value: "interes_asegurable",
        align: "center",
        sortable: true,
      },
      {
        text: "Días de pago",
        value: "dia_pago",
        align: "left",
        sortable: false,
      },
      { text: "", value: "actions", align: "end", sortable: false },
    ],
    companias: [],
    labels: {
      title: "Compañias",
      search: "Buscar compañia",
      new: "Nueva compañia",
    },
  }),

  created() {
    this.setup();
  },
  computed: {
    ...mapGetters("Companias", ["getItems"]),

    items() {
      return this.getItems;
    },
  },
  methods: {
    ...mapActions("Companias", ["GET", "POST", "PUT", "DELETE"]),

    setup() {
      this.loading = true;
      this.GET()
        .catch((error) => {
          this.snackbar(error.info);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    saveItem(data) {
      let METHOD = this.POST;
      this.loadingForm = true;
      const formData = { ...data };
      if (this.selected) {
        METHOD = this.PUT;
        formData.id = this.selected.id;
      }
      // Condiciones especiales de la data.
      formData.id_empresa = formData.id_empresa.toString();
      formData.numero_documento = formData.numero_documento.replace(
        /[^A-Z0-9]/gi,
        ""
      );
      METHOD(formData)
        .then((response) => {
          this.snackbar(response.info, "success");
          this.closeForm();
          this.setup();
        })
        .catch((response) => {
          this.snackbar(response.info);
        })
        .finally(() => {
          this.loadingForm = false;
        });
    },

    deleteItem(item) {
      this.$refs[this.refs.confirm]
        .show({
          title: "Eliminar compañía",
          description: "¿Desea eliminar esta compañía?",
        })
        .then((answer) => {
          if (answer) {
            item.loading = false;
            this.DELETE(item.id)
              .then((response) => {
                this.snackbar(response.info, "success");
                this.setup();
              })
              .catch((error) => {
                this.snackbar(error.info);
              })
              .finally(() => {
                item.loading = false;
              });
          }
        });
    },
  },
};
</script>
