export const dateMask = "##/##/####";

export const dateMaskNewFormat = "####-##-##";

export const phoneMask = "#########";

export const rutMask = (value) => {
  const rut = value.replace(/^0+|[^0-9kK]+/g, "").toUpperCase();

  let result = rut.slice(-4, -1) + "-" + rut.substr(rut.length - 1);
  for (let i = 4; i < rut.length; i += 3) {
    result = rut.slice(-3 - i, -i) + "." + result;
  }

  return [result.length > 12 ? result.substring(0, 11) : result];
};

export const valorMask = (value) => {
  const valor = value.replace(/[^\d.]|\.(?=.*\.)/g, "");

  return [valor];
};

export default {
  rutMask,
  dateMask,
  dateMaskNewFormat,
};
